import React, { Component } from 'react';

import logo from '../assets/images/logo.png'
import logo2x from '../assets/images/logo@2x.png'
import logo3x from '../assets/images/logo@3x.png'

import Scroll from '../components/Scroll'
import title from '../assets/images/elia-games.png'

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
      visibilityClass: '',
    };
  }
  toggleMenu = value => {
    this.setState({ openMenu: value });
  };

  handleScroll = () => {
    const { visibilityClass } = this.state;
    //console.log("Yof :", window.pageYOffset)
    if (window.pageYOffset > 1) {
      if (visibilityClass !== 'navbar-shrink') {
        this.setState({ visibilityClass: 'navbar-shrink' });
      }
    } else {
      if (visibilityClass === 'navbar-shrink') {
        this.setState({ visibilityClass: '' });
      }
    }
  };
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    const { openMenu, visibilityClass } = this.state;
    return (
      <nav
        className={`navbar navbar-expand-lg navbar-light ${visibilityClass}`}
        id="mainNav"
      >
        <div className="container">
          <a className="navbar-brand" href="#page-top">
          <img src={logo}
                srcSet={logo2x+" 2x, "+ logo3x + " 3x"}
          className="logo" />
          <img src={title}/>
          </a>
          <button
            onClick={_ => this.toggleMenu(!openMenu)}
            className={`navbar-toggler navbar-toggler-right ${
              openMenu ? '' : 'collapsed'
            }`}
            type="button"
            aria-controls="navbarResponsive"
            aria-expanded={openMenu}
            aria-label="Toggle navigation"
          >
            Menu
            <i className="fas fa-bars"></i>
          </button>

          <div
            className={`collapse navbar-collapse ${openMenu ? 'show' : ''}`}
            id="navbarResponsive"
          >
            <ul className="navbar-nav ml-auto">
             
              <li className="nav-item">
                
              <Scroll type='id' element='games' timeout={2}>
                  <a className="nav-link" href="#games">
                      Games
                  </a>
              </Scroll>
                
                
              </li>
              <li className="nav-item">
                
                <Scroll type='id' element='about' timeout={2}>
                  <a className="nav-link" href="#about">
                      About Us
                  </a>
                </Scroll>

              </li>
              <li className="nav-item">
                
              <Scroll type='id' element='join' timeout={2}>
                  <a className="nav-link" href="#join">
                      Contact Us
                  </a>
              </Scroll>
                
                
              </li> 
              
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
